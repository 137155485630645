import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";

import imgCB from "../../../assets/image/features/why-choose-us.png";
import imgT from "../../../assets/image/features/avatar1.png";
import imgStars from "../../../assets/image/svg/five-stars-rating.svg";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-15 pb-11 pt-lg-25 pb-lg-24 pt-xl-30 pb-xl-28 position-relative paperwapper" style={{backgroundColor: "#ffe01b"}}>
        <Container>
          <Row className="align-items-center justify-content-md-center">
            <Col sm="10" lg="5" className="order-lg-2 position-static">
              <div className="content-img position-relative gr-abs-lg gr-abs-lg-ry-center w-lg-50 text-center text-lg-right">
                <img className="h-100 w-100 w-lg-75" src={imgCB} alt="" />
              </div>
            </Col>
            <Col sm="11" lg="7" className="order-lg-1">
              <Row>
                <Col lg="10" xl="10">
                  <div className="content-text mb-9 mb-lg-18 mt-11 mt-lg-0">
                    <h2 className="title gr-text-3 mb-6">
                      Why choose Camelo?
                    </h2>
                    <h3 className="gr-text-8 lead mb-9 pr-11">
                      Because everything your business needs is in one place, created and supported by a passionate team.
                    </h3>
                    <div className="content-widget mt-8">
                      <Row>
                        <Col md="12">
                          <div className="single-widget d-flex align-items-center py-2">
                            <div className="widget-icon text-black mr-6 gr-text-8 d-flex align-items-center">
                              <i className="icon icon-check-simple"></i>
                            </div>
                            <h3 className="w-title gr-text-8 mb-0">
                              No more spreading work across different apps
                            </h3>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="single-widget d-flex align-items-center py-2">
                            <div className="widget-icon text-black mr-6 gr-text-8 d-flex align-items-center">
                              <i className="icon icon-check-simple"></i>
                            </div>
                            <h3 className="w-title gr-text-8 mb-0">
                              Saves you and your employees both money and time
                        </h3>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="single-widget d-flex align-items-center py-2">
                            <div className="widget-icon text-black mr-6 gr-text-8 d-flex align-items-center">
                              <i className="icon icon-check-simple"></i>
                            </div>
                            <h3 className="w-title gr-text-8 mb-0">
                              Connects with your team members instantly
                            </h3>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="single-widget d-flex align-items-center py-2">
                            <div className="widget-icon text-black mr-6 gr-text-8 d-flex align-items-center">
                              <i className="icon icon-check-simple"></i>
                            </div>
                            <h3 className="w-title gr-text-8 mb-0">
                              Everyone knows what they’re supposed to do
                            </h3>
                          </div>
                        </Col>
                        <Col md="8">
                        </Col>

                      </Row>
                    </div>

                  </div>
                  <div className="testimonial-wrapper mt-6 mt-md-0">
                    <div
                      className="single-testimonial media py-6"
                      data-aos="fade-left"
                      data-aos-duration="800"
                    >
                      <div className="media-body">
                        {/* <img src={imgStars} style={{width: "5rem", paddingBottom: "1rem"}} /> */}
                        <h3 className="gr-text-6 lead mb-0 pr-xl-6 mb-8 open-quote">
                          All of my staff's shifts, tasks, and time-off requests are in one place—my phone. Managing my staff has become easier with just a few swipes and touches on the screen.
                        </h3>
                        <span className="name gr-text-11 gr-text-color-opacity d-flex">
                          <div className="media-icon mr-8">
                            <img className=" circle-sm-2" src={imgT} alt="" />
                          </div>
                          <div>
                            <strong>J&D Cafe</strong><br />
                            Jared Lebsack (Business owner)
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
