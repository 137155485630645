import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import CollapsibleTable from "./CollapsibleTable";
import CrossfadeImage from "react-crossfade-image";

import imgTeamAnnouncement from "./../../../assets/image/features/team-announcement.png";
import imgBringTeamTogether from "./../../../assets/image/features/bring-your-team-together.png";
import imgOneOnOne from "./../../../assets/image/features/one-on-one-conversation.png";
import imgKeepTeamUpdates from "./../../../assets/image/features/keep-team-updates.png";

const WORKPLACE_COMM_ROWS = [
  {
    title: "Make announcements with ease",
    description: "Reach 100% of your workforce via Announcements channel and keep everyone on the same page.",
    img: imgTeamAnnouncement,
  },
  {
    title: "1:1 chat",
    description: "Send a direct message to another team member and chat privately.",
    img: imgOneOnOne,
  },
  {
    title: "Bring your team together",
    description: "Different conversations for different projects, topics, and teams are in one place. Every member in a channel receives the same messages and stays on the same page.",
    img: imgBringTeamTogether,
  },
  {
    title: "Keep your team up to date on shifts and tasks",
    description: "Notifications of new schedules, shifts, and tasks are sent to each employee’s inbox instantly.",
    img: imgKeepTeamUpdates,
  },
];


const Content3 = () => {
  const [openRow, setOpenRow] = useState(0);

  return (
    <>
      {/* <!-- Content1 Area --> */}
      <div id="elCommunication" className="content-section pt-11 pt-lg-20 pb-11 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-stretch">
            <Col
              xs="11"
              sm="10"
              md="9"
              lg="6"
              className="mb-6 mb-md-13 mb-lg-0"
            >
              <div
                className="bg-desert rounded-10 h-100 text-center  d-flex justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <CrossfadeImage className="w-100" src={WORKPLACE_COMM_ROWS[openRow].img} alt="" />
              </div>
            </Col>
            <Col sm="10" md="9" lg="6" xl="5" className="offset-xl-1">
              <div className="content-text pr-lg-10">
                <h2 className="gr-text-4 mb-9">
                  Workplace communication
                </h2>
                <p className="gr-text-9 mb-9">
                  Free messaging app for effective team communication. With Camelo, every team member can never miss important information.
                </p>
                <CollapsibleTable rows={WORKPLACE_COMM_ROWS} openRow={openRow} setOpenRow={setOpenRow} expandedBg="bg-hover-blue"></CollapsibleTable>
                <a
                  href="/features/workplace-communication"
                  target="_blank"
                  className="btn-link with-icon gr-text-blue pl-9 text-black gr-text-7 font-weight-bold mt-8"
                >
                  Learn more{" "}
                  <i className="icon icon-tail-right font-weight-bold text-primary"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content3;
