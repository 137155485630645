import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import CollapsibleTable from "./CollapsibleTable";

import CrossfadeImage from "react-crossfade-image";
import imgTasking from "./../../../assets/image/features/task-management.png";
import imgReduceChurn from "./../../../assets/image/features/reduce-employee-churn.png";

const TABLE_ROWS = [
  {
    title: "Tasking",
    description: "Assign tasks and make sure your employees are clear on what to do everyday. Provide easily-accessible instructions right on the shift card so that your employees know what to do in their shift.",
    img: imgTasking,
  },
  {
    title: "Increase employee engagement",
    description: "Each staff’s shifts, tasks, time off, and conversations are put together in one place. Everyone knows exactly what to do.",
    img: imgReduceChurn,
  },
];


const Content4 = () => {
  const [openRow, setOpenRow] = useState(0);

  return (
    <>
      {/* <!-- Content1 Area --> */}
      <div id="elMoreOthers" className="content-section pt-11 pt-lg-20 pb-11 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-stretch">
            <Col sm="10" md="9" lg="6" xl="5" className="">
              <div className="content-text pr-lg-10">
                <h2 className="gr-text-4 mb-9">
                  Fast onboarding and staff engagement
                </h2>
                <p className="gr-text-9 mb-9">
                  Simple to pick up and use for both managers and staff. Employees can view their shifts, tasks, time off, and conversations in one app.
                </p>
                <CollapsibleTable openRow={openRow} setOpenRow={setOpenRow} rows={TABLE_ROWS} expandedBg="bg-hover-green"></CollapsibleTable>
              </div>
            </Col>
            <Col
              xs="11"
              sm="10"
              md="9"
              lg="6"
              className="mb-6 mb-md-13 mb-lg-0 offset-xl-1"
            >
              <div
                className="bg-desert rounded-10 h-100 text-center"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <CrossfadeImage className="w-100" src={TABLE_ROWS[openRow].img} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content4;
