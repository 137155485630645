import React, { useContext } from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import GlobalContext from "../../../context/GlobalContext";
import { Link } from "gatsby";

import imgHM from "../../../assets/image/features/employees-scheduling.png";
import imgHC2 from "../../../assets/image/features/receive-shifts-update.png";

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="pb-15 pt-24 pt-lg-32 pb-lg-28" id="hero-area-animation">
        <Container id="animation-area-1">
          <Row className="align-items-center justify-content-center">
            <Col
              xs="9"
              md="7"
              lg="5"
              className="offset-xl-1 align-self-sm-end order-lg-2"
            >
              <div
                className="hero-img image-group-p12 position-relative mb-9 mb-sm-15 mb-lg-0"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <img className="w-100" src={imgHM} alt="" />
              </div>
              <div className="animation-item">
                <div
                  className=" d-flex overflow-hidden shadow-10 rounded-circle w-40 w-xl-40 hero-sm-card-2 hero-card-1-animation"
                  style={{
                    border: "solid 6px white",
                    backgroundColor: "#FFE01B",
                  }}
                  id="an-item-1p2"
                >
                  <div className="small-card-img">
                    <img src={imgHC2} alt="" className="w-100" />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              md="9"
              lg="7"
              xl="6"
              className="order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <div className="hero-content section-title text-center text-lg-left">
                <h1 className="gr-text-3 font-weight-bold mb-8">
                  Schedule and manage your teams efficiently
                </h1>
                <p className="gr-text-9 gr-text-color pr-md-7">
                  <div className="d-flex align-items-start py-2">
                    <div className="gr-text-8 text-primary mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-10">
                      Create, update, and manage schedules and tasks in minutes
                    </h3>
                  </div>
                  <div className="d-flex align-items-start py-2">
                    <div className="gr-text-8 text-primary mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-10">
                      Track and record employee time and attendance precisely
                    </h3>
                  </div>
                  <div className="d-flex align-items-start py-2">
                    <div className="gr-text-8 text-primary mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-10">
                      Message your team members and receive notifications
                      instantly
                    </h3>
                  </div>
                  <div className="d-flex align-items-start py-2">
                    <div className="gr-text-8 text-primary mr-6 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <h3 className="gr-text-10">
                      Store all your necessary files in one place
                    </h3>
                  </div>
                </p>
                <div className="hero-btns mt-11">
                  <Link
                    onClick={() => {
                      if (typeof window.gtag_report_conversion === "function") {
                        window.gtag_report_conversion(
                          process.env.SIGNUP_URL,
                        );
                      }
                    }}
                    to={process.env.SIGNUP_URL}
                    target="__blank"
                    className="btn btn-primary with-icon gr-hover-y"
                  >
                    Start scheduling for Free
                    <i className="icon icon-tail-right font-weight-bold"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
