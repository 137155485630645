import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Link as LinkScroll} from "react-scroll";

const JumpToSection = ({to}) => {
  return (
    <div className="footer">
      <LinkScroll
        id={"jumpTo" + to}
        to={to}
        spy={true}
        smooth="easeInOutCubic"
        offset={-80}
        duration={420}
        css={`cursor: pointer;`}
        className="btn-learn-more btn-link with-icon gr-text-10 font-weight-bold"
      >
        Click to learn more{" "}
        <i className="icon icon-tail-right font-weight-bold"></i>
      </LinkScroll>
    </div>
  );
};

const FeatureSection = ({index, title, className, to, children}) => {
  const onClick = () => {
    let linkTo = document.querySelector("#jumpTo" + to);
    linkTo.click();
    return false;
  };

  return (
    <div
      onClick={onClick.bind(this)}
      className="single-timeline-feature text-center px-6 px-md-4  pb-8 d-flex flex-column rounded-10"
    >
      <div className={"count circle-lg gr-text-4 mx-auto mb-12 text-white " + className}>
        <span>{index}</span>
      </div>
      <div className="content px-xl-7 flex-grow-1 pb-4">
        <h3 className="title gr-text-9 mb-6">{title}</h3>
        <p className="gr-text-11">
          {children}
        </p>
      </div>
      <JumpToSection to={to} />
    </div>
  );

};

const Process = () => {
  return (
    <>
      {/* <!-- Process Area --> */}
      <div className="bg-default-5 how-section features-tour pb-13 pt-lg-18 pb-lg-13 mb-23 mb-lg-29">
        <Container>
          <Row className="justify-content-center">
            <Col xs="8">
              <div className="section-title text-center mb-11 mb-lg-17">
                <h2 className="title gr-text-4 mb-7">An all-in-one toolkit for managing your staff </h2>
              </div>
            </Col>
          </Row>
          <div className="gr-timeline-wrapper d-flex justify-content-center align-items-stretch">
            <FeatureSection index="1" className="bg-red" to="elScheduling" title="Employee Scheduling">
              Create, make changes, and publish staff schedules quickly. Fill shifts with a few touches and swipes. Schedules are sent straight to staff’s phones.
            </FeatureSection>
            <FeatureSection index="2" className="bg-yellow2" to="elTimeClock" title="Time & Attendance Tracking">
              Track employee hours and attendance precisely. Record and export accurate timesheets for payroll.
            </FeatureSection>
            <FeatureSection index="3" className="bg-blue" to="elCommunication" title="Communication & Legal Compliance">
              Keep your team updated on information and announcements. Engage in real-time 1-on-1 and group conversations. Keep your business stay compliant with the labor laws.
            </FeatureSection>
            <FeatureSection index="4" className="bg-green" to="elMoreOthers" title="Fast Onboarding & Staff Engagement">
              Simple to pick up and use for both managers and staff. Employees can view their shifts, tasks, time off, and conversations in one app.
            </FeatureSection>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Process;
