import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import CollapsibleTable from "./CollapsibleTable";

import CrossfadeImage from "react-crossfade-image";

import imgTimeClock from "./../../../assets/image/features/employee-time-clocks.png";
import imgManageLeaves from "./../../../assets/image/features/manage-leaves.png";
import imgApproveExport from "./../../../assets/image/features/approve-export-timesheets.png";
import imgCollectTimesheets from "./../../../assets/image/features/easily-collect-and-verify-timesheets.png";

const TIMECLOCK_ROWS = [
  {
    title: "Clock in and clock out",
    description: "Turn any phone into time clock and get rid of paper timesheets. Employees clock in and clock out with their phones, using location-based, Wi-Fi, and facial validation. Get notified when they’re running late or forget to clock in.",
    img: imgTimeClock,
  },
  {
    title: "Manage leaves and attendance",
    description: "Have an overview of who’s on, who’s off, and who’s late. Employees can send time-off and leave requests right on the app. Managers can view all requests in one place and decide to approve or deny them, then find replacements for absent employees quickly.",
    img: imgManageLeaves,
  },
  {
    title: "Easily collect & verify timesheets",
    description: "Timesheets are recorded, saved, and stored automatically in one place. Prevent employees from clocking in early. Automatically clock them out in case they forget.",
    img: imgCollectTimesheets,
  },
  {
    title: "Approve & Export timesheets",
    description: "Camelo keeps track of your employees’ hours so you don’t have to do it manually. Simply review timesheets at the end of each pay period, edit and approve, then export for accurate payroll processing.",
    img: imgApproveExport,
  },
];


const Content2 = () => {
  const [openRow, setOpenRow] = useState(0);

  return (
    <>
      {/* <!-- Content1 Area --> */}
      <div id="elTimeClock" className="content-section pt-11 pt-lg-20 pb-11 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-stretch">
            <Col sm="10" md="9" lg="6" xl="5" className="">
              <div className="content-text pr-lg-10">
                <h2 className="gr-text-4 mb-9">
                  Time &amp; Attendance
                </h2>
                <p className="gr-text-9 mb-9">
                  Track employee hours and attendance precisely. Record and export accurate timesheets for payroll.
                </p>
                <CollapsibleTable openRow={openRow} setOpenRow={setOpenRow} rows={TIMECLOCK_ROWS} expandedBg="bg-hover-yellow"></CollapsibleTable>
                <a
                  href="/features/time-and-attendance"
                  target="_blank"
                  className="btn-link with-icon gr-text-blue pl-9 text-black gr-text-7 font-weight-bold mt-8"
                >
                  Learn more{" "}
                  <i className="icon icon-tail-right font-weight-bold text-primary"></i>
                </a>
              </div>
            </Col>
            <Col
              xs="11"
              sm="10"
              md="9"
              lg="6"
              className="mb-6 mb-md-13 mb-lg-0 offset-xl-1"
            >
              <div
                className="bg-desert rounded-10 h-100 text-center d-flex justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <CrossfadeImage className="w-100" src={TIMECLOCK_ROWS[openRow].img} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content2;
