import React from "react";
import SEO from "../../components/Layout/SEO";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/camelo/features/Hero";
import Process from "../../sections/camelo/features/Process";
// import Brands from "../../sections/camelo/features/Brands";
import Content1 from "../../sections/camelo/features/Content1";
import Content2 from "../../sections/camelo/features/Content2";
import Content3 from "../../sections/camelo/features/Content3";
import Content4 from "../../sections/camelo/features/Content4";
import WhyUs from "../../sections/camelo/features/WhyUs";
import CTA from "../../sections/camelo/features/CTA";

const FeaturesPage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Camelo Overview | Employee Scheduling | Time Tracking | Messaging"
          description="Learn more about how the Camelo app can help you simplify shift scheduling, tracking work hours & communicating with your team."
        ></SEO>
        <Hero />
        {/* <Brands /> */}
        <Process />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <WhyUs />
        <CTA />
      </PageWrapper>
    </>
  );
};

export default FeaturesPage;
