import React, {useState} from "react";
import {Container, Row, Col, Collapse} from "react-bootstrap";

const CollapsibleRow = ({id, title, description, setOpenRow, expanded, expandedBg}) => {

  return (
    <div className={"rounded-10 mb-3 overflow-hidden bg-hover-clickable " + (expanded ? expandedBg : "")}>
      <a
        className="mb-0 btn py-6 pl-9 pr-7 border-bottom-0 accordion-trigger arrow-icon w-100"
        aria-controls={"collapse-" + id}
        onClick={() => setOpenRow(id)}
        aria-expanded={expanded}
      >
        <div
          className="gr-text-7 font-weight-bold text-left text-blackish-blue p-0 "
        >
          {title}
        </div>
      </a>
      <Collapse in={expanded}>
        <div>
          <div className="card-body gr-color-blackish-blue-opacity-7 pt-0 pl-9 pr-15 gr-text-9 pb-9">
            <div className="pt-6">
              {description}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

const CollapsibleTable = ({rows, expandedBg, openRow, setOpenRow}) => {
  return (
    <>
      { rows.map((row, index) =>
        <CollapsibleRow
          key={index}
          id={index}
          expanded={openRow == index}
          expandedBg={expandedBg}
          setOpenRow={setOpenRow} {...row} />)}
    </>
  );
};

export default CollapsibleTable;
