import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import CollapsibleTable from "./CollapsibleTable";
import CrossfadeImage from "react-crossfade-image";

import imgBuildShifts from "./../../../assets/image/features/build-shifts-fast.png";
import imgNotifyShifts from "./../../../assets/image/features/notify-new-shifts.png";
import imgMultipleLocations from "./../../../assets/image/features/multiple-location-management.png";
import imgSwapShifts from "./../../../assets/image/features/swap-shifts.png";

const EMPLOYEE_SCHEDULING_ROWS = [
  {
    title: "Build smart schedule in minutes",
    description: "Schedule staff based on availability, skills, and roles with a few taps. Easily fill shifts for different locations of your business with the right staff.",
    img: imgBuildShifts,
  },
  {
    title: "Notify schedule updates instantly",
    description: "New schedules and updates are sent straight to your team via desktop or mobile. Requesting a confirmation to make sure your staff have received the updates.",
    img: imgNotifyShifts,
  },
  {
    title: "Manage shifts flexibly",
    description: "Employees can swap shifts with colleagues, and choose open shifts if they want more work hours. Managers can then approve the requests or turn on the auto-approve feature.",
    img: imgSwapShifts,
  },
  {
    title: "Scale across multiple locations",
    description: "Manage employees of multiple locations. Grant permission for other managers so that only they can create schedules and make changes.",
    img: imgMultipleLocations,
  },
];


const Content1 = () => {
  const [openRow, setOpenRow] = useState(0);

  return (
    <>
      {/* <!-- Content1 Area --> */}
      <div id="elScheduling" className="content-section pt-11 pt-lg-20 pb-11 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-stretch">
            <Col
              xs="11"
              sm="10"
              md="9"
              lg="6"
              className="mb-6 mb-md-13 mb-lg-0"
            >
              <div
                className="bg-desert rounded-10 h-100 text-center d-flex justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                <CrossfadeImage className="w-100" src={EMPLOYEE_SCHEDULING_ROWS[openRow].img} alt="" />
              </div>
            </Col>
            <Col sm="10" md="9" lg="6" xl="5" className="offset-xl-1">
              <div className="content-text pr-lg-10">
                <h2 className="gr-text-4 mb-9">
                  Employee Scheduling
                </h2>
                <p className="gr-text-9 mb-9">
                  Create, make changes, and publish staff schedules quickly. Fill shifts with a few touches and swipes. Schedules are sent straight to staff’s phones.
                </p>
                <CollapsibleTable openRow={openRow} setOpenRow={setOpenRow} rows={EMPLOYEE_SCHEDULING_ROWS} expandedBg="bg-hover-red"></CollapsibleTable>
                <a
                  href="/features/scheduling-software"
                  target="_blank"
                  className="btn-link with-icon gr-text-blue pl-9 text-black gr-text-7 font-weight-bold mt-8"
                >
                  Learn more{" "}
                  <i className="icon icon-tail-right font-weight-bold text-primary"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content1;
